#s-form-id {
  display: none;
}

#select-input {
  width: 26rem;
  height: 7rem;
  border-radius: 3.5rem;
  border: solid 0.2rem #0a4275;
}

.form-select option{
  font-size: 1.5rem;
}

.form-select {
  width: 65% !important;
  font-size: 1.8rem !important;
  color: #0a4275 !important;
  border: none !important;
  margin-top: 2rem !important;
  margin-left: 5rem !important;
}

.display-s {
  display: flex !important;
}

.display-n {
  display: none !important;
}

.s-form {
  width: 100%;
  min-height: 100vh;
  border: solid 2.5rem #0a4275;
  display: flex;
  flex-direction: column;
}

.s-form-switch {
  width: 100%;
  height: 10vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: sans-serif;
}

.switch-form {
  text-align: center;
  font-size: 2rem;
  color: black;
  font-family: Poppins-Regular;
  font-weight: normal;
}

.s-form-input {
  width: 100%;
  min-height: 75vh;
  display: flex;
  padding-top: 2rem;
  flex-direction: column;
}

.s-form-input-field {
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.s-form-input-field input {
  width: 26rem;
  height: 7rem;
  border-radius: 3.5rem;
  padding-left: 7rem;
  border: solid 0.2rem #0a4275;
  font-size: 1.8rem;
  color: #0a4275;
}

.form-reg {
  display: flex;
  width: 100%;
  min-height: 70vh;
}

.s-form-img-container {
  position: absolute;
  width: 6rem;
  height: 6rem;
  background-color: #0a4275;
  border-radius: 3rem;
  margin-left: -19rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 0.5rem;
}

.s-form-input-box {
  display: flex;
  justify-content: space-evenly;
}

.s-form-div {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.s-form-button {
  width: 100%;
  height: 5vh;
}

.add-form {
  width: 35rem;
  height: 6rem;
  border-radius: 3rem;
  background-color: #0a4275;
  text-align: center;
  color: white;
  font-size: 2rem;
  font-family: Poppins-Regular;
  font-weight: normal;
  margin: 0 auto;
}

@media screen and (min-width: 320px) and (max-width: 992px) {
  .s-form-input {
    flex-direction: column;
  }

  .s-form-img-container {
    margin-left: -14rem;
 
  }
  

  .s-form-input-field {
    margin-bottom: 1.5rem;
  }

  .s-form-div {
    width: 100%;
    align-items: center;
  }

  .s-form-input-box {
    flex-direction: column;
    align-items: center;
  }

  .s-form-img-container {
    height: 2.5rem;
    width: 4rem;
    border-radius: 1.5rem;
  }

  .form-reg {
    flex-direction: column;
  }
  .s-form-img-container img {
    width: 1.5rem;
  }

  .s-form-input-field input {
    height: 3.5rem;
    border-radius: 1.75rem;
    width: 95%;
    padding-left: 5rem;
    font-size: 1.4rem;
  }

  .add-form {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 0;
    width: 12rem;
    height: 3rem;
    font-size: 1.5rem;
  }

  .form-select{
    width: 64% !important;
    font-size: 1.5rem !important;
    color: #0a4275 !important;
    border: none !important;
    margin-top: 0.7rem !important;
    margin-left: 3rem !important;
  }

  #select-input{
    height: 35px;
    width: 190px;
  }
}
