#pass-admin{width: 100%;}

.form-creates {
    width: 100%;
    height: 100vh;
}

.form-containers {
    width: 75%;
    margin: 0 auto;
    height: 75vh;
    background-color: #00477D;
}

.form-house {
    display: flex;
    flex-direction: column;
    padding-left: 5rem;
    padding-top: 5rem;
}

.owner {
    width: 90%;
    height: 10rem;
    background-color: aqua;
}

#left-mensaje{
    width: 90%;
}

.details {
    width: 90%;
    height: 10rem;
    background-color: bisque;
    margin-bottom: 1rem;
}

.details input {
    width: 15rem;
    height: 3rem;
    background-color: white;
    border-radius: 1.5rem;
    border: none;
    padding-left: 1.5rem;

}
.header-about {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.header-title {
    width: 100%;
    text-align: center;
    margin-bottom: 10rem;
    margin-top: 5rem;
    height: 10rem;
}

#responsive-contact{
    display: none;
}


.header-title h2 {
    font-family: Poppins-Semibold;
    font-size: 2.7rem;
    font-weight: normal;
    color: #1F3E72;
}

.contact-forms {
    width: 100%;
    height: 55rem;
    background-color: #00477D;
    display: flex;
    justify-content: center;


}

.forms {
    width: 109.0rem;
    height: 49.4rem;
    background-color: white;
    display: flex;
    position: absolute;
    border-radius: 3rem;
    padding-top: 3.2rem;
    margin-top: -5%;
    box-shadow: inset 0px 3px 6px #00000029, 0px 3px 6px #00000029;
}

.form-lefts {
    width: 50%;
    display: flex;
    flex-direction: column;
    padding-left: 5rem;
}

#form-lefts-admin{
    width: 100%;
}

.form-lefts h2 {
    font-family: Poppins-Semibold;
    font-size: 1.7rem;
    font-weight: normal;
    color: #1F3E72;
    margin-bottom: 2rem;
}

.form-lefts input {
    width: 100%;
    height: 5.5rem;
    background-color: #F5F6FF;
    font-size: 1.8rem;
    font-family: Poppins-Medium;
    font-weight: normal;
    padding-left: 2rem;
    border: none;
    border-radius: 1.5rem;
    color: black;
    margin-bottom: 2.1rem;
}

.form-lefts a {
    width: 10rem;
    height: 4rem;
    display: block;
    font-size: 1.8rem;
    font-weight: normal;
    font-family: Poppins-Regular;
    text-align: center;
    background-color: #2848C6;
    padding-top: 0.6rem;
    text-decoration: none;
    border-radius: 1.1rem;
    color: white;
}

.form-rights {
    width: 50%;
    display: flex;
    flex-direction: column;
}

.form-rights h2 {
    font-family: Poppins-Semibold;
    font-size: 1.7rem;
    font-weight: normal;
    color: #1F3E72;
    margin-bottom: 2rem;
}

.form-rights input {
    width: 80%;
    height: 30rem;
    background-color: #F5F6FF;
    font-size: 1.8rem;
    font-family: Poppins-Medium;
    font-weight: normal;
    padding-left: 2rem;
    border: none;
    border-radius: 1.5rem;
    color: black;
}


.header-about {
    margin-bottom: 15rem;
}

#toggle:checked~.header-about {
    filter: blur(8px);
}

#toggle:checked~.menu {
    filter: blur(8px);
}




#fot-contact {
    margin-top: 30rem;
}


#send-contact {
    display: none;
}


#contact-id{
    margin-top: 45rem;
}

.change-form{
    padding-top: 2rem;
    text-align: center;
    background-color: white;
}

.change-form h2{
    font-size: 1.8rem;
    color: black;
}

.client-fo{
    display: inline;
    text-align: center;
    width: 100%;
    margin-bottom: 5rem;
}

.signups{
    font-family: Poppins-Regular;
    font-weight: normal;
    font-size: 1.8rem;
}

.dives{
    display: flex;
}

.superficies{
    display: flex;
    flex-direction: row;
    margin-bottom: 3rem;
    width: 100%;

}

.superficie{
    margin-right: 5rem;
    position: relative;
    width: 50%;
}

@media screen and (min-width: 320px) and (max-width: 992px) {

    .client-fo{
        width: 100%;
        display: inline;
        margin-bottom: 2rem;
    }
    .form {
        width: 30rem;
        height: auto;
        flex-direction: column;
        border-radius: 0;
        box-shadow: none;

    }.form-lefts{
        width: 100%;
        padding-left: 2rem;
        padding-right: 2rem;
    }

  


    .dives{
        flex-direction: column;
    }

    .contact-form {
        background-color: transparent;

    }

    .header-title{
        height: 5rem;
        margin-bottom: 0;

    }

    .header-title h2{
        font-size: 2.7rem;
    }


    .form-left a {
        display: none;
    }

    .form-left {
        width: 100%;
        padding-left: 2rem;
    }

    .form-left h2 {
        font-size: 2rem;
    }

    .form-left input {
        width: 90%;
        height: 3.25rem;
        border-radius: 1.625rem;
        font-size: 1.3rem;
    }

    #send-contact {
        width: 10.3rem;
        height: 3.9rem;
        margin: 0 auto;
        display: block;
        font-size: 1.3rem;
        font-weight: normal;
        font-family: Poppins-Regular;
        text-align: center;
        background-color: #2848C6;
        margin-top: 3rem;
        text-decoration: none;
        border-radius: 1.1rem;
        color: white;
        margin-bottom: 2rem;
    }

    .form-right {
        width: 100%;
        padding-left: 2rem;
        margin-bottom: 2rem;

    }

    .form-right h2 {
        font-size: 2rem;
    }

    .form-right input {
        font-size: 1.3rem;
        width: 90%;
        height: 16.9rem;
    }

    .image-gallery-icon{
        z-index: 1 !important;
    }

    #responsive-contact{
        display: flex;
        height: 75rem;
    }

    .form-lefts{
        align-items: center;
    }
    #desktop-contact{
        display: none;
    }

    .superficies {
        display: flex;
        flex-direction: column;
    }
    .superficie {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-right: 0rem;
        margin-bottom: 2rem;
    }

    #containers{
        width: 100%;
        min-height: 235rem;
    }
    


}

#containers{
    width: 100%;
    min-height: 75rem;
}


#create-admin {
    height: 100%;
    align-items: center;
}

#create-admin-form {
    width: 90%;
    height: 90%;
    display: flex;
    flex-direction: row;
    border-radius: 2rem;
}
#create-admin-form-usuario {
    width: 75%;
    height: 80%;
}

#admin-create-seller-button {
    position: absolute;
    margin-left: 56.5rem;
    margin-top: 40rem;
    width: 10%;
    color: white;
    padding-left: 0;
    background-color: #2366CC;
}




#form-h2{
    font-size: 1.5rem;
}
#flex{
    flex-direction: column;
}
.precio-house input {
    height: 5rem;
    width: 12.5rem;
    margin-right: 2rem;
}

.precio-house {
    margin-top: 5.3rem;
    display: flex;
    align-items: center;
}

#precio-ad{
    margin-bottom: 0;
    margin-bottom: 2rem;
}

#precio-ads{
    margin-right: 2rem;
    margin-bottom: 0;
}


.form-house-create {
    background-color: white;
    display: flex;
    flex-direction: column;
    position: absolute;
    padding-top: 3.2rem;
    margin-top: 0;
}

#mascotas {
    width: 12.5rem;
}

#input-imagenes {
    margin-top: 2rem;
}

#formFileMultiple {
    height: 5.5rem;
    padding-top: 1.5rem;
}

#select-vendedor {
    width: 100%;
    height: 5.5rem;
    background-color: #F5F6FF;
    font-size: 1.8rem;
    font-family: Poppins-Medium;
    font-weight: normal;
    padding-left: 2rem;
    border: none;
    border-radius: 1.5rem;
    color: black;
    margin-bottom: 2.1rem;
    margin-right: 6rem;
}

#select-vendedor option {
    font-size: 1.8rem;
}

#admin-create-house-button {
   text-align: center;
    width: 20%;
    color: white;
    padding-left: 0;
    background-color: #00477D;
    opacity: 0.8;
    margin-top: 3.5rem;
}

#croses-ad{
    position:absolute;
    z-index: 2;
    width: 3.5rem;
    margin-left: 1rem;
    margin-top: 1rem;
}

#admin-edit-button{
  
    width: 20%;
    color: white;
    padding-left: 0;
    background-color: #00477D;
    opacity: 0.8;
}

#admin-create-usuario-button {
    position: absolute;
    margin-left: 0rem;
    margin-top: 40rem;
    width: 15%;
    color: white;
    padding-left: 0;
    background-color: #00477D;
    opacity: 0.8;
}

#admin-show-descripcion {
    width: 80%;
    height: 25rem;
    background-color: #F5F6FF;
    font-size: 1.8rem;
    font-family: Poppins-Medium;
    font-weight: normal;
    padding-left: 2rem;
    border: none;
    border-radius: 1.5rem;
    color: black;
    margin-bottom: 2.1rem;
    margin-right: 6rem;
}

.h2-show-buyer {
    padding-top: 1.6rem;
}