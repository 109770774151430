@font-face {
  font-family: Poppins-Bold;
  src: url(../src/assets/fonts/Poppins/Poppins-Bold.ttf);
  font-display: swap;
}

@font-face {
  font-family: Poppins-Semibold;
  src: url(../src/assets/fonts/Poppins/Poppins-SemiBold.ttf);
  font-display: swap;
}

@font-face {
  font-family: Poppins-Regular;
  src: url(../src/assets/fonts/Poppins/Poppins-Regular.ttf);
  font-display: swap;
}

@font-face {
  font-family: Poppins-Medium;
  src: url(../src/assets/fonts/Poppins/Poppins-Medium.ttf);
  font-display: swap;
}

@font-face {
  font-family: Poppins-Light;
  src: url(../src/assets/fonts/Poppins/Poppins-Light.ttf);
  font-display: swap;
}

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 62.5%;
}

.text-danger-registro{
  font-size: 1.5rem;
  color: red;
  position:absolute;
  margin-left: -24rem;
margin-top: -1.5rem;
}


