.tables {
    width: 75%;
    margin: 0 auto;
    padding-top: 5rem;
}


#create {
  width: 8rem;
  height: 3rem;
  font-size: 1.5rem;
  border-radius: 0.5rem;
}

#admin-table{
    font-size: 8rem;
}