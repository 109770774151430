.sidebars{
    background-color: #00477D ;
    height: 100vh;
    width: 9rem;
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-top-right-radius:3rem;
    border-bottom-right-radius: 3rem;
    color: white;
    transition:600ms ease;
    font-size: 3rem;
    opacity: 1;
}

a{
    text-decoration: none;
}
  
.side-txt{
    font-family: Poppins-Medium;
    font-size: 1.7rem;
    font-weight: normal;
    color: #FB994E;

}

.side-logo{
    display: flex;
    align-items: center;
    justify-content: center;
}

.side-logo img{
    width: 5rem;
}

.side-img{
    width: 3rem;
    margin-bottom: 1rem;
}

.logout-ico{
    width: 3rem;
}

.side-logo{
    width: 100%;
    height: 15%;
}
.side-icons{
    width: 100%;
    height: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}
.side-off{
    width: 100%;
    height: 15%;
    padding-top: 2rem;
}

.side-off a{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.side-icon{
    width: 100%;
    height: 7rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.sidebars:hover .side-txt{
    display: inline;

}

.sidebars:hover .link-text{
    display: block;
}
.sidebars a:hover{
    color: white;
}



.side-txt{
    display:none;
    margin-top: 1rem;
    color: white;
}

.linkto{
    text-decoration: none;
}

.side-txt:hover{
    color: #FB994E;
}

.link-text{
    display: none;
    margin-top: 1rem;
}


.sidebars:hover{
    width: 15rem;
}