.section {
    position: relative;
    min-height: 100vh;
    background: #00477D;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.label-marketing{
    font-size: 1.2rem;
    color: black;
    font-family: Poppins-Medium;
    font-weight: normal;
}

.check-marketing{
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
}

#scales{
    width: auto;
    margin-right: 1rem;
}

.section.active {
    background: #00477D;
}

.section .container {
    position: relative;
    width: 90rem;
    min-height: 60rem;
    background: #F7AE40;
    box-shadow: 0 1.5rem 5rem rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.section .container .user {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
}

.section .container .user .imgBx {
    position: relative;
    width: 50%;
    height: 100%;
    background: white;
    transition: 0.5;
    display: flex;
    align-items: center;
    justify-content: center;
}


.section .container .user .imgBx img {
    position: absolute;
    width: 15rem;
    height: 22.5rem;
}

.section .container .user .formBx {
    position: relative;
    width: 50%;
    height: 100%;
    background: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4rem;
    transition: 0.5s;
}

.section .container .user .formBx h2 {
    font-size: 1.8rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 0.2rem;
    text-align: center;
    width: 100%;
    margin-bottom: 1rem;
    color: #555;
}

.section .container .user .formBx input {
    width: 100%;
    background: #F5F5F5;
    color: #333;
    padding: 1rem;
    border: none;
    outline: none;
    box-shadow: none;
    font-size: 1.4rem;
    margin: 0.8rem 0;
    letter-spacing: 0.1rem;
    font-weight: 300;
    font-family: Poppins-Light;
}

.section .container .user .formBx input[type="submit"] {
    max-width: 10rem;
    background: #F7AE40;;
    color: #fff;
    cursor: pointer;
    font-size: 1.4rem;
    font-weight: 500;
    letter-spacing: 0.1rem;
    transition: 0.5s;
}

.section .container .user.signupBx .formBx input[type="submit"] {
    background: #F7AE40;
}

.section .container .user .formBx .signup {
    position: relative;
    margin-top: 2rem;
    font-size: 1.2rem;
    letter-spacing: 0.1rem;
    color: #555;
    text-transform: uppercase;
    font-weight: 300;
}

.section .container .user .formBx .signup a {
    font-weight: 600;
    text-decoration: none;
    color: #fd6A02;
    font-size: 1.2rem;
}

.section .container.active .user .formBx .signup a {
    font-weight: 600;
    text-decoration: none;
    color: #fd6A02;
    font-size: 1.2rem;
}

.section .container .signupBx {
    pointer-events: none;
}

.section .container.active .signupBx {
    pointer-events: initial;
}

.section .container .signupBx .formBx {
    top: 100%;
}

.section .container.active .signupBx .formBx {
    top: 0;
}

.section .container .signupBx .imgBx {
    top: -100%;
    transition: 0.5s;
}

.section .container.active .signupBx .imgBx {
    top: 0;
}

.section .container .signinBx .formBx {
    top: 0;
}

.section .container.active .signinBx .formBx {
    top: 100%;
}

.formBx > form {
    font-family: Poppins-Light !important;
}

.section .container .signinBx .imgBx {
    top: 0;
}

.section .container.active .signinBx .imgBx {
    top: -100%;
}

#image{
    width: 95%;
    padding-top: 1rem;
}

#formwidth{
    width: 100%;
}

.politica{
    text-decoration:underline ;
    font-size: 1.2rem;
    font-family: Poppins-Regular;
}

@media screen and (min-width: 320px) and (max-width: 992px) {

    .section .container .signinBx .imgBx {
        display: none;
    }

    .section .container .signupBx .imgBx {
        display: none;
    }

    .section .container .user .formBx {
        width: 100%;
    }

    /* .section .container .user .formBx input {
        padding: 0;
    } */
  
}