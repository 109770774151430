#about-h2 {
    margin-bottom: 4rem;
}

.s2-about {
    width: 100%;
    margin-bottom: 4rem;

}

.s2-about h2 {
    font-size: 3rem;
    font-weight: 400;
    color: #00477D;
    text-align: center;
    font-family: Poppins-Regular;
}

.s2-about-title img {
    width: 100%;
    height: 45rem;
    border-radius: 2rem;
    margin-bottom: 5rem;
}

.s2-about-title {
    width: 70%;
    margin: 0 auto;
}

.s2-about-container {
    width: 90%;
    background-color: #00477D;
    border-radius: 2.3rem;
    padding-top: 3.1rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}


.about-mission-logo img{
    width: 30rem;
}

.about-mission-logo{
    margin-bottom: 5rem;
}

.about-leaders{
    display: flex;
    width: 100%;
}
.about-leader{
    flex: 50%;
    padding: 25px;
}

.face{
    display: block;
    margin: 0 auto 10px auto;
    text-align: center;
    clear: both;
    width: 30rem;
    border-radius: 50%;
}

.s2-about-container h2 {
    font-family: Poppins-Medium;
    font-size: 2.8rem;
    color: white;
    text-align: center;
    padding-left: 7rem;
    padding-right: 7rem;
}

.s2-about-container h4 {
    font-family: Poppins-Medium;
    font-size: 1.5rem;
    color: #aaa;
    text-align: center;
    padding-left: 7rem;
    padding-right: 7rem;
}


.s2-about-mission {
    margin-top: 10rem;
}

.about-mission {
    font-size: 6rem;
    font-family: Poppins-Regular;
    color: #F7AE40;
    font-weight: 600;
    text-align: center;
}

.s2-about-title h3 {
    width: 20.3rem;
    height: 6.6rem;
    color: white;
    background-color: #00477D;
    font-family: Poppins-Medium;
    font-weight: normal;
    font-size: 2rem;
    text-align: center;
    padding-top: 1.8rem;
    text-decoration: none;
    margin: 0 auto;
    margin-bottom: 5.3rem;
    display: block;
    border-radius: 0.5rem;
    margin-top: 5rem;
}



@media screen and (min-width: 320px) and (max-width: 992px) {

    .s2-about{
        margin-bottom: 4rem;
    }

    #about-h2{
        font-size: 1.6rem;
    }

    .about-mission-logo img{
        width: 12rem;
    }

    .s2-about-container {
        width: 95%;
        margin: 0 auto;
        height: auto;
    }

    .s2-about-title img{
        width: 30rem;
        height: auto;
        
    }

    .s2-about h2{
        font-size: 2.6rem;
    }

    .s2-about-title h3{
        font-size: 2.6rem;
    }

    .about-leaders{
        display: block;
    }
    .about-leader{
        padding-top: 10px;
    }
}


