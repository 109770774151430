.whatsapp-chat {
    position: fixed;
    top: 90%;
    left: 95%;
    z-index: 2;
}

.chat-link img {
    width: 6rem;
}

@media screen and (min-width: 320px) and (max-width: 992px) {

    .chat-link img {
        width: 4rem;
    }

    .whatsapp-chat {
        position: fixed;
        top: 90%;
        left: 85%;
        z-index: 2;
    }
}