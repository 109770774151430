.acordeon{
    width: 100%;
    height: 100%;
}

.accordion-item{
    width: 100%;
}


.accordion-header button{
    background: none;
    font-family: Poppins-Regular;
    font-size: 2.2rem;
    border: none;
    font-weight: 500;
    color: black;
    margin-bottom: 2rem;
}

.accordion-body{
    display: none;
}

.show .accordion-body{
    display: block;
    font-size: 2rem;
    font-family: Poppins-Regular;
    font-weight: normal;
    color: #3C3C43D9;
    margin-bottom: 2rem;
}

.line-acordeon{
    width: 100%;
    background-color: black;
    height: 0.2rem;
    margin-bottom: 3rem;
}

.tutos-intro{
    width: 100%;
    margin-bottom: 10rem;
    margin-top: 15rem;
}

.intro-h1{
    text-align: center;
    font-size: 4rem;
}

.tutos-container {
    width: 100%;
    min-height: 150rem;
    display: flex;
    padding: 5% 0 15rem 10%;
  }
  
  .video-des {
    font-family: Poppins-Regular;
    font-weight: normal;
    margin-bottom: 2rem;
    margin-bottom: 5rem;
    margin-top: 3rem;
    letter-spacing: 0.075rem;
    font-size: 1.6rem;
  }
  
  .tutos-left {
    width: 30%;
    height: 40%;
    top: 0;
    position: sticky;
    padding: 2rem;
    padding-top: 0;
  }
  
  .tutos-left h2 {
    font-family: Poppins-Regular;
    font-size: 1.8rem;
    padding-top: 15px;
    color: black;
    font-weight: normal;

  }
  .tutos-right {
    width: 60%;
    height: 100%;
  }
  
  @media screen and (min-width: 320px) and (max-width: 992px) {
    .accordion-header button {
      font-size: 2rem;
    }
  
    .show .accordion-body {
      font-size: 1.3rem;
    }
  
    .video-des {
      font-size: 1.3rem;
    }
  
    .tutos-container {
      padding: 15% 10% 15rem 10%;
    }
  
    .tutos-left {
      display: none;
    }
  
    .tutos-right {
      width: 100%;
    }
  }