.newpass-container {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #00477d;
  }
  
  .email-newpass {
    width: 50%;
    background-color: white;
    box-shadow: rgba(120, 118, 118, 0.16) 1rem 1rem 1rem;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .email-newpass h1 {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .email-newpass h2 {
    text-align: center;
    margin: 2rem;
    font-size: 1.4rem;
  }
  
  .email-newpass form {
    display: flex;
    padding: 2rem;
    flex-direction: column;
    align-items: center;
    width: 100%;
    font-family: Poppins-Light;
  }
  
  .email-newpass input {
    width: 65%;
    padding: 1rem;
    border: none;
    outline: none;
    box-shadow: none;
    font-size: 1.4rem;
    margin: 0.8rem 0;
    letter-spacing: 0.1rem;
    font-weight: 300;
  }
  
  .email{
    background: #f5f5f5;
    font-family: Poppins-Light;
  }
  
  .submit {
    border: none;
    color: #fff;
    background-color: #f7ae40;
    font-family: Poppins-Medium;
  }
  