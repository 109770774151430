footer{
    height: 45rem;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: #00477D;
}

.fot-content{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 90%;
}

.fot-left{
    width: 30%;
    height: 100%;
    padding-left: 12rem;
    display: flex;
    align-items: center;
}

.fot-left img{
    width: 15rem;
}

.fot-right{
    height: 100%;
    width: 70%;
    display: flex;
    padding-top: 11.5rem;
}

.fot-container{
    display: flex;
    flex-direction: column;
    width: 25%;
    height: 100%;
    margin-right: 1rem;
}

.fot-container h2{
    font-family: Poppins-Semibold;
    font-size: 2.2rem;
    color: white;
    margin-bottom: 1.5rem;
}

.fot-container h3{
    font-family: Poppins-Regular;
    color: #C5C5C5;
    margin-bottom: 1rem;
    font-size: 1.8rem;
}

.fot-container-img{
    width: 100%;
    display: flex;
}

.fot-container-img img{
    margin-right: 1rem;
}

.fot-copyright{
    width: 100%;
    height: 10%;
    text-align: center;
}

.fot-copyright h2{
    font-family: Poppins-Regular;
    font-size: 1.6rem;
    color: white;
}


@media screen and (min-width: 320px) and (max-width: 992px) {

    footer{
        height: 130rem;
    }
    .fot-left{
        width: 100%;
        padding-left: 0;
        height: 10%;
        padding-top: 5rem;
    }

    .fot-left img{
        width: 20rem;
        margin: 0 auto;
    }

    .fot-content{
        height: 95%;
        flex-direction: column;
    }

    .fot-right{
        height: 85%;
        width: 100%;
        flex-direction: column;
        margin-bottom: 5rem;
    }

    .fot-container{
        width: 40%;
        margin: 0 auto;
        margin-bottom: 5rem;
    }

    .fot-copyright{
        height: 5%;
        margin-bottom: 5rem;
        height: 5%;
    }
}