.profile{
    width: 100%;
    min-height: 100vh;
    display: flex;
    margin-bottom: 10rem;
}

.left-profile{
    height: 100%;
    width: 30%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.user-img{
    width: 50%;
    height: 40%;
    margin-top: 5rem;
    margin-bottom: 2rem;
}

.user-img img{
    width: 100%;
}

.user-domain{
    text-align: center;
    height: 15%;
    margin-bottom: 2rem;
}

.user-domain h2{
    font-family: Poppins-Semibold;
    font-size: 2rem;
    font-weight: normal;
    color:#00477D;
}

.user-domain h3{
    font-family: Poppins-Medium;
    font-size: 1.6rem;
    font-weight: normal;
    color: black;
}

.user-clients{
    height: 15%;
    text-align: center;
    margin-bottom: 2rem;
}

.user-clients h2{
    font-family: Poppins-Semibold;
    font-size: 2rem;
    font-weight: normal;
    color:#00477D;
}

.user-clients h3{
    font-family: Poppins-Medium;
    font-size: 1.6rem;
    font-weight: normal;
    color:black;
}

.right-profile{
    height: 100%;
    width: 70%;
    display: flex;
    flex-direction: column;
    padding-top: 5rem;
}

.profile-container{
    display: flex;
    width: 100%;
    height: 50%;
}

.profile-box{
    display: flex;
    flex-direction: column;
    width: 33%;
}

.profile-title{
    width: 100%;
    margin-bottom: 2rem;
}

.profile-title h2{
    font-family: Poppins-Semibold;
    font-size: 2.5rem;
    font-weight: normal;
    color:#00477D;
}

.profile-details{
    display: flex;
    flex-direction: column;
    margin-bottom: 1rem;
}

.profile-details h2{
    font-family: Poppins-Regular;
    font-size: 1.5rem;
    font-weight: normal;
    color:black;
    opacity: 0.5;
}

.profile-details h3{
    font-family: Poppins-Regular;
    font-size: 1.8rem;
    font-weight: normal;
    color:black;
}

#profile-input{
    font-family: Poppins-Regular;
    font-size: 1.8rem;
    font-weight: normal;
    color:black;
    width: 80%;
    border-radius: 0.5rem;
    border: none;
}


#profile-inputs{
    font-family: Poppins-Regular;
    font-size: 1.8rem;
    font-weight: normal;
    color:black;
    width: 80%;
    height: 20rem;
    border-radius: 0.5rem;
    border: none;
}

#profile-input-center{
    font-family: Poppins-Regular;
    font-size: 1.8rem;
    font-weight: normal;
    color:black;
    width: 70%;
    border-radius: 0.5rem;
    border: none;
    text-align: center;
}

@media screen and (min-width: 320px) and (max-width: 992px) {

    .profile{
        flex-direction: column;
    }

    .left-profile{
        width: 100%;
    }

    .user-img{
        height: 70%;
        margin-bottom: 2rem;
    }

    .user-domain{
        margin-bottom: 2rem;
    }

    .right-profile{
        width: 100%;
    }

    .profile-container{
        flex-direction: column;
        height: 100%;
    }

    .profile-box{
        width: 70%;
        margin: 0 auto;
        margin-bottom: 2rem;
    }
}