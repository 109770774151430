.bm-burger-button {
  display: none;
}

.active {
  color: #f7ae40 !important;
  font-weight: bold;
}

@media (min-width: 992px) {
  .sidebar-background {
    display: none;
  }

  .bm-overlay {
    display: none;
  }

  .bm-menu {
    display: none;
  }
}

@media screen and (min-width: 320px) and (max-width: 992px) {
  /* Position and sizing of burger button */
  .bm-burger-button {
    display: block;
    position: absolute;
    width: 3.3rem;
    height: 2.5rem;
    left: 3rem;
    top: 4%;
  }

  .sidebar-background {
    height: 10vh;
    background-color: #00477d;
  }

  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #f7ae40;
    height: 12% !important;
  }

  /* Color/shape of burger icon bars on hover*/
  .bm-burger-bars-hover {
    background: #ff9900;
  }

  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  /*.bm-menu-wrap {
        width: 100% !important;
    }*/

  /* Color/shape of close button cross */
  .bm-cross {
    background: #bdc3c7;
  }

  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
  }

  /* General sidebar styles */
  .bm-menu {
    background: #00477d;
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
    display: flex;
    padding-left: 1rem;
  }

  /* Morph shape necessary with bubble or elastic */
  .bm-morph-shape {
    fill: #373a47;
  }

  /* Wrapper for item list */
  .bm-item-list {
    color: #b8b7ad;
    padding: 0.8em;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  /* Individual item */
  .bm-item {
    display: block;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: white;
  }

  .navbar-li {
    margin: 3rem;
    color: none;
  }

  /* Styling of overlay */
  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }

  .profile-line {
    flex-direction: row;
    display: flex;
    align-items: center;
    margin: 0;

    width: 100%;
    justify-content: space-between;
  }
}
