.plan-container {
  display: flex;
  width: 100%;
  min-height: 80rem;
  justify-content: space-evenly;
  margin-top: 10rem;
  margin-bottom: 10rem;
  position: relative;
}

.plan-lines {
  position: absolute;
  z-index: -1;
  margin-top: -12%;
  width: 100%;
}

.plan-box {
  display: flex;
  flex-direction: column;
  height: 100%;
  /*width: 35.7rem;*/
  background-color: #00477d;
  padding-top: 3rem;
  border-radius: 1rem;
}

.plan-title {
  width: 100%;
  min-height: 20rem;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  display: flex;
}

.plan-price {
  width: 100%;
  height: 15%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.plan-price h2 {
  font-family: Poppins-Bold;
  font-size: 4.4rem;
  color: #f7ae40;
  margin-left: 0.5rem;
}

.plan-price h3 {
  font-family: Poppins-Bold;
  font-size: 2.4rem;
  color: #f7ae40;
  margin-bottom: 0;
}

.price-user {
  padding-top: 1.5rem;
  font-family: Poppins-Regular;
  font-size: 1.4rem;
  color: #f7ae40;
  align-items: center;
  justify-content: center;
}

.price-add {
  font-family: Poppins-Regular;
  font-size: 1.3rem;
  font-weight: 100;
  font-style: italic;
  color: #f7ae40;
}

.plan-type {
  width: 100%;
  height: 10%;
  text-align: center;
}

.plan-type h4 {
  font-family: Poppins-Regular;
  font-size: 3.4rem;
  font-weight: 400;
  color: white;
}

.plan-features {
  width: 100%;
  height: 50%;
  display: flex;
  flex-direction: column;
  padding-left: 4rem;
  padding-right: 4rem;
  margin-bottom: 4rem;
}

.boxs {
  display: flex;
  align-items: center;
  margin-bottom: 2.2rem;
}

.check {
  margin-right: 2rem;
}

.feature h5 {
  font-family: Poppins-Light;
  font-size: 1.6rem;
  font-weight: 500;
  color: white;
}

.feature-deactivated h5 {
  font-family: Poppins-Light;
  font-size: 1.6rem;
  font-weight: 500;
  color: #777777;
}

.button-display {
  display: flex;
  width: 100%;
  padding-bottom: 2rem;
}

.button-yellow {
  width: 100%;
  /*width: 50%; /*When using button blue*/
  height: 15%;
  margin: 0 2.5%;
  /*margin: 0 2.5% 0 1.25%; /*When using button blue*/
  cursor: pointer;
}

.button-yellow h2 {
  height: 6rem;
  background-color: #f7ae40;
  text-align: center;
  border-radius: 0.5rem;
  border-width: 1px;
  font-family: Poppins-Light;
  font-size: 2rem;
  padding-top: 1.5rem;
  margin: 0 auto;
  cursor: pointer;
}

.button-yellow button {
  background-color: #f7ae40;
  border: none;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 2rem;
  font-family: Poppins-Light;
  color: black;
  cursor: pointer;
}

.modal {
  display: flex;
  height: 74rem;
  width: 85.7rem;
  background-color: #00477d;
  border-radius: 1rem;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  padding: 5px;
}

.modal > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  margin: 15px 17px 9px 6px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 34px;
  background-color: transparent !important;
  color: #f7ae40;
  border: transparent !important;
}
/*
video {
  border-radius: 1rem;
}

.video > .close {
  cursor: pointer;
  position: absolute;
  display: block;
  line-height: 20px;
  right: 10px;
  top: 10px;
  font-size: 34px;
  background: transparent !important;
  color: #f7ae40;
  border: transparent !important;
  z-index: 10000;
}

.video-react.video-react-fluid {
  border-radius: 1rem !important;
}

.video-react .video-react-poster {
  background-color: #00477d !important;
  border-radius: 1rem !important;
}*/

.popup-container {
  flex-direction: column;
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
}

.popup-header {
  width: 100%;
  border-bottom: 1px solid gray;
  font-family: Poppins-Bold;
  font-size: 4.4rem;
  color: #f7ae40;
  margin-left: 0.5rem;
  text-align: center;
  padding: 5px;
}

.popup-main {
  flex-direction: column;
  display: flex;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  width: 100%;
}

.popup-info {
  flex-direction: column;
  height: 100%;
  display: flex;
  justify-content: center;
  padding: 0 3rem;
  color: white;
  width: 100%;
}

.popup-info > h1 {
  color: #f7ae40;
  font-size: 4rem;
}
/*
.popup-info > h2 {
  margin-top: 0.5rem;
  color: #f7ae40;
  font-size: 1.2rem;
}
*/

.popup-features {
  display: flex;

  padding: 11px 0;
  border-radius: 1rem;
  color: #000000;
  margin: 1rem 0;
  font-family: Poppins-Regular;
}

.popup-left {
  font-size: 16px;
  width: 50%;
  text-align: left;
  margin: 0 1rem;
}

.popup-right {
  width: 50%;
  padding-left: 1rem;
  margin-right: 1rem;
  font-size: 16px;
  border: none;
  border-radius: 1rem;
  text-align: lect;
}

.popup-right > .boxs {
  height: 30px;
}
.popup-left > .boxs {
  height: 30px;
}
.popup-input {
  display: flex;
  background-color: #f6f6f6;
  padding: 11px 0;
  border-radius: 1rem;
  color: #000000;
  margin: 1rem 0;
  font-family: Poppins-Regular;
}

.input-left {
  font-size: 16px;
  width: 50%;
  text-align: right;
  margin: 0 1rem;
}

.input-right {
  width: 50%;
  padding-left: 1rem;
  margin-right: 1rem;
  font-size: 16px;
  border: none;
  border-radius: 1rem;
  text-align: lect;
}

.input-button {
  background-color: #f7ae40;
  border: none;
  border-radius: 1rem;
  text-align: center;
  text-decoration: none;
  font-size: 2rem;
  font-family: Poppins-Light;
  color: black;
  cursor: pointer;
  width: 100%;
  padding: 11px 0;
}

/* From Popup react component */

.popup-content {
  margin: auto;
  width: 100%;
  padding: 0.5rem;
}

.popup-arrow {
  color: rgb(255, 255, 255);
}
[role="tooltip"].popup-content {
  width: 200px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 0px 3px;
}

.popup-overlay {
  background: rgba(0, 0, 0, 0.5);
}
[data-popup="tooltip"].popup-overlay {
  background: transparent;
}

/****************************************************************************************/

.button-blue {
  width: 50%;
  height: 15%;
  margin: 0 1.25% 0 2.5%;
  cursor: pointer;
}
.button-blue h2 {
  height: 6rem;
  background-color: #00477d;
  text-align: center;
  color: white;
  border-style: solid;
  border-radius: 0.5rem;
  border-color: white;
  border-width: 1px;
  font-family: Poppins-Light;
  font-size: 2rem;
  padding-top: 1.5rem;
  margin: 0 auto;
  cursor: pointer;
}

.player {
  width: 85.7rem;
  position: relative;
  padding: 0 3rem 0 1.5rem;
}

@media screen and (min-width: 320px) and (max-width: 992px) {
  .plan-container {
    flex-direction: column;
    align-items: center;
    min-height: 90rem;
    margin-bottom: 15rem;
    margin-top: 15rem;
  }

  .plan-box {
    width: 80%;
    min-height: 40rem;
    margin-bottom: 5rem;
  }

  .plan-price {
    margin-bottom: 2rem;
  }

  .modal {
    width: 80%;
    height: 90rem;
  }

  .plan-type {
    margin-bottom: 3rem;
  }

  .plan-features {
    padding-left: 2.2rem;
    height: 65%;
    margin-bottom: 2rem;
  }

  .button-display {
    display: block;
  }

  .button-yellow {
    width: auto;
    margin: 2.5%;
  }

  .button-blue {
    width: auto;
    height: 15%;
    margin: 2.5%;
  }

  .popup-features {
    display: block;
  }

  .popup-left {
    width: 100%;
  }

  .popup-right {
    width: 100%;
  }
}
