#legal-h2 {
  margin-bottom: 4rem;
}

h4 a {
  font-size: 1.5rem;
  font-weight: 400;
  text-align: center;
  white-space: pre-line;
}

.s2-legal {
  width: 100%;
  margin: 2rem auto;
}

.s2-legal h1 {
  text-align: center;
  font-family: Poppins-Regular;
  font-size: 3rem;
  font-weight: 700;
  color: #00477d;
  margin-bottom: 1rem;
}

.s2-legal h2 {
  font-family: Poppins-Medium;
  padding: 1.3rem 0;
  font-size: 1.8rem;
  text-align: left;
}

.s2-legal h3 {
  font-family: Poppins-Medium;
  font-size: 1.8rem;
  text-align: left;
}

.s2-legal h4 {
  font-size: 1.5rem;
  font-weight: 400;
}

.s2-legal-title img {
  width: 100rem;
  height: 45rem;
  border-radius: 2rem;
  margin-bottom: 5rem;
}

.s2-legal-title {
  width: 70%;
  margin: 2rem auto;
}

.h4-legal {
  width: 70%;
  margin: 1rem auto;
  text-align: justify;
}

.s2-legal-container {
  border-radius: 2.3rem;
  padding: 3.3rem 15%;
  display: block;
  flex-direction: column;
  align-items: center;
  text-align: justify;
}

.s2-legal-container h2 {
  font-family: Poppins-Medium;
  padding: 2.3rem 0;
  font-size: 1.8rem;
  text-align: left;
}

.s2-legal-title h3 {
  width: 20.3rem;
  height: 6.6rem;
  background-color: #00477d;
  font-family: Poppins-Medium;
  font-weight: normal;
  font-size: 2rem;
  text-align: center;
  padding-top: 1.8rem;
  text-decoration: none;
  margin: 0 auto;
  margin-bottom: 5.3rem;
  display: block;
  border-radius: 0.5rem;
  margin-top: 5rem;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

.table-overflow {
  width: 70%;
  margin: 2rem auto;
}

td {
  font-size: 1.5rem;
  padding: 1.25rem;
}

table thead tr th {
  font-size: 1.5rem;
  padding: 1.25rem;
  text-align: left;
  font-weight: 600;
}

tr {
  border-bottom: 1px solid #dfe2e7;
}
tr:nth-child(even) {
  background-color: #f2f2f2;
}
table tbody,
table tfoot {
  border-bottom: 1px solid #dfe2e7;
}

table tbody tr td + td {
  border-left: 1px solid #dfe2e7;
  padding: 1.25rem;
}

table tbody tr {
  text-align: left;
}

h4 {
  font-weight: 400;
}

@media screen and (min-width: 320px) and (max-width: 992px) {
  .s2-legal-container h2 {
    font-size: 1.6rem;
  }

  .legal-mission-logo img {
    width: 12rem;
  }

  .s2-legal-container {
    width: 95%;
    margin: 0 auto;
    height: 50rem;
  }

  .s2-legal-title img {
    width: 30rem;
    height: auto;
  }

  .s2-legal {
    padding: 10% 0;
  }

  .s2-legal h2 {
    font-size: 1.6rem;
  }

  .s2-legal-title h3 {
    font-size: 1.6rem;
  }

  .table-overflow {
    overflow-x: auto;
  }
}
