.s1 {
  /*min-height: 100vh;
  /*background-image: url(../assets/images/homepage-0.png);*/
  background-repeat: no-repeat;
  background-size: contain;
}

svg {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
}

.swal-button {
  background-color: #00477d;
}

.swal-button--confirm {
  background-color: #00477d;
}
.swal-footer {
  background-color: rgb(245, 248, 250);
  margin-top: 32px;
  border-top: 1px solid #e9eef1;
  overflow: hidden;
}

.container-svg {
  display: inline-block;
  vertical-align: middle;
  overflow: hidden;
  height: 200px;
  z-index: -1;
}

.container-svg-responsive {
  display: none;
  z-index: -1;
}

.s1 header {
  display: flex;
  flex-direction: column;
}

.container-home {
  height: 15vh;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #00477d;
}

.container-home div {
  width: 20%;
  margin-right: 20%;
  padding-left: 12.3rem;
  padding-top: 3.1rem;
  height: 100%;
}

.logo {
  width: 20rem;
}

main {
  /*height: 85vh;*/
  margin-left: 14.2rem;
  margin-right: 11.5rem;
  display: flex;
  align-items: center;
}

.left {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 85%;
  /*padding-top: 11rem;*/
}

.left h1 {
  font-family: Poppins-Regular;
  font-size: 4.8rem;
  color: #f7ae40;
  font-weight: 600;
  margin-bottom: 2.8rem;
}

.left h2 {
  font-family: Poppins-Light;
  font-size: 3.6rem;
  font-weight: normal;
  color: white;
  margin-bottom: 1rem;
}

.left h3 {
  font-family: Poppins-Regular;
  font-size: 3.6rem;
  color: white;
  font-weight: 600;
  margin-bottom: 4rem;
}

.left h4 {
  font-family: Poppins-Regular;
  width: 25.2rem;
  height: 6rem;
  background-color: #f7ae40;
  font-size: 2rem;
  color: black;
  text-align: center;
  padding-top: 1.5rem;
  border-radius: 0.5rem;
}

.right {
  width: 100%;
  height: 100%;
}

.right img {
  width: 100%;
}

.bottom {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.bottom h4 {
  width: 25.2rem;
  height: 6rem;
  background-color: #f7ae40;
  font-size: 2rem;
  font-weight: 600rem;
  color: black;
  text-align: center;
  padding-top: 1.5rem;
  border-radius: 0.5rem;
}

.bottom h3 {
  font-family: Poppins-Regular;
  font-size: 1.6rem;
  color: black;
  font-weight: 600;
}

.demo {
  width: 25.2rem;
  height: 6rem;
  background-color: #f7ae40;
  font-size: 2rem;
  font-weight: 600rem;
  color: white;
  text-align: center;
  padding-top: 1.5rem;
  border-radius: 0.5rem;
}

/* SECTION 2 */

.s2 {
  width: 100%;
  padding-left: 12.5rem;
  margin-bottom: 15rem;
}

.s2-responsive {
  display: none;
}

.absolute-line {
  position: absolute;
  margin-top: 14%;
  margin-left: 11%;
  z-index: -1;
}

.box {
  display: flex;
  flex-direction: column;
}

.s2-container {
  display: flex;
  width: 100%;
  align-items: center;
  margin-bottom: 5rem;
}

.s2-container-text {
  width: 45%;
}

.s2-container-image {
  width: 40%;
  margin-right: 13.7rem;
}

.s2-container-image img {
  width: 100%;
}

.s2-title {
  display: flex;
  margin-bottom: 2.7rem;
}

.s2-title h2 {
  font-family: Poppins-Regular;
  font-size: 4.8rem;
  font-weight: 700;
  color: #f7ae40;
}

.s2-title h3 {
  font-family: Poppins-Regular;
  font-size: 4.8rem;
  font-weight: 600;
  color: #00477d;
}

.s2-text h4 {
  font-family: Poppins-Regular;
  font-size: 2rem;
  color: #777777;
  font-weight: 500;
}

/* SECTION 3 FAQ */

.s3 {
  display: flex;
  width: 100%;
  min-height: 75rem;
}

.s3-responsive {
  display: none;
}

.s3-img {
  width: 40%;
  height: 100%;
  padding-left: 5rem;
  margin-right: 10rem;
  padding-top: 9rem;
}

.s3-img img {
  width: 100%;
}

.faq {
  width: 90%;
}

.s3-faq {
  width: 60%;
  height: 100%;
  padding-top: 3rem;
}

.faq-title {
  width: 100%;
  margin-bottom: 5rem;
}

.faq-title h2 {
  font-size: 4rem;
  font-family: Poppins-Regular;
  font-weight: 600;
}

@media (max-width: 1475px) {
  .left h1 {
    font-size: 3.8rem;
  }
  .left h2 {
    font-size: 2.6rem;
  }
}

@media (max-width: 1350px) {
  .left h1 {
    font-size: 2.8rem;
  }
  .left h2 {
    font-size: 1.6rem;
  }
}

@media (max-width: 1040px) {
  .left h1 {
    font-size: 2.5rem;
  }
  .left h2 {
    font-size: 1.4rem;
  }
}

@media (max-width: 808px) {
  .plan-container {
    margin-top: 5rem !important;
  }
}

@media screen and (min-width: 320px) and (max-width: 992px) {
  .s1 {
    /*min-height: 100vh;
    background-image: url(../assets/images/responsive-1.png);*/
    background-repeat: no-repeat;
    background-size: contain;
  }

  .container-home {
    display: none;
  }

  .container-svg {
    display: none;
  }

  .container-svg-responsive {
    display: inline-block;
    vertical-align: middle;
    overflow: hidden;
    height: 100px;
    z-index: -1;
  }

  .right {
    width: 70%;
  }

  main {
    margin: 0;
    width: auto;
    flex-direction: column;
  }

  .left {
    /*padding-top: 25%;*/
    padding-right: 30%;
    padding-left: 3rem;
  }

  .left h1 {
    font-size: 2.4rem;
    line-height: 91%;
    margin-bottom: 10%;
  }

  .left h2 {
    font-size: 1.4rem;
    margin-bottom: 5%;
  }

  .left h3 {
    font-size: 1.4rem;
    margin-bottom: 5%;
  }

  .left h4 {
    width: 60%;
    height: 3.5rem;
    font-size: 1.4rem;
    font-family: Poppins-Regular;
    padding-top: 0.75rem;
    box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.25);
    background-color: #f7ae40;
    opacity: 0.9;
  }

  .s2 {
    display: none;
  }

  .s2-responsive {
    display: flex;
    margin-bottom: 10rem;
  }

  .s2-container {
    margin-bottom: 10rem;
    width: 100%;
  }

  .s2-container-text {
    width: 100%;
  }

  .s2-container-image {
    width: 100%;
  }

  .absolute-line {
    display: none;
  }

  .s2-title h2 {
    font-size: 3rem;
  }

  .s2-title h3 {
    font-size: 3rem;
  }

  .s2-text h4 {
    font-size: 1.3rem;
  }

  .s2-container-text {
    width: 80%;
    margin: 0 auto;
  }

  .s3 {
    display: none;
  }

  .s3-responsive {
    display: flex;
    width: 100%;
  }

  .s3-faq {
    width: 85%;
    margin: 0 auto;
  }

  .faq {
    width: 100%;
  }

  .faq-title h2 {
    font-size: 3rem;
  }

  .s3-img {
    width: 100%;
    height: 100%;
    padding-left: 0;
    margin-right: 0;
    padding-top: 0;
    margin-bottom: 5rem;
  }
}
