a {
  text-decoration: none;
}

.navbar {
  height: 100%;
  width: 100%;
  padding-top: 3.1rem;
  display: block;
}

.navbar-ul {
  display: flex;
  justify-content: space-evenly;
  height: 100%;
  list-style-type: none;
}

/* .nav-link{
    font-family: Poppins-Medium;
    font-weight: normal;
    font-size: 2.4rem;
    color: white;
} */

#login {
  width: 16rem;
  height: 4rem;
  color: black;
  text-align: center;
  padding-top: 0.5rem;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  font-size: 2rem;
  display: block;
  background-color: #f7ae40;
  margin-bottom: 0;
}

#drop-link {
  font-size: 1.8rem;
  font-family: Poppins-Regular;
  margin-top: -3.5%;
  /*color: black !important;*/
}

aside#drop-link {
  color: #f7ae40;
}

.active {
  color: #00477d;
  font-weight: bold;
}

.navbar-li a {
  font-family: Poppins-Medium;
  font-weight: normal;
  font-size: 2.4rem;
  color: white;
}

.navbar-li a:hover {
  color: #f7ae40;
}

#dropdown {
  margin-right: 2rem;
}

.dropdown-menus {
  right: 0;
  display: none;
  position: absolute;
  height: auto;
  background-color: #00477d;
  width: auto;
  border-radius: 0.5rem 0.5rem 0.5rem 0.5rem;
  border: 1px solid #fff;
  align-items: center;
  flex-direction: column;
  justify-content: space-evenly;
  position: absolute;
  z-index: 3000;
  padding: 2rem;
  margin-right: 2rem;
  transition-property: ease-in 1s;
}

.dropdown-menus:hover {
  display: block;
}

.line-menu {
  width: 100% !important;
  background-color: black;
  height: 0.1rem !important;
  margin-bottom: 3rem;
}

.profile-icon:hover ~ .dropdown-menus {
  display: block;
  -webkit-transition: all .5s, 2s .5s linear;
  transition: all .5s, 2s .5s linear;
}

hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #f7ae40;
  margin: 1em 0;
  padding: 0;
}

.profile-icon img {
  width: 4rem !important;
  height: 4rem !important;
  border-radius: 2rem;
}

#profile-img{
  width: 100%;
  padding: 0;
  margin: 0;
}

@media screen and (min-width: 320px) and (max-width: 992px) {
  .navbar {
    display: none;
  }

  hr {
    border-top: 1px solid #f7ae40 !important;
    margin: 3rem;
  }

  .navbar-li a:hover {
    color: #f7ae40;
  }
}