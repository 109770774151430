.s4-demo {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 90rem;
  margin-bottom: 0rem;
  margin-top: 5rem;
}

.s4-title {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 10%;
  margin-bottom: 5rem;
}

.s4-title h2 {
  font-family: Poppins-Regular;
  font-size: 4rem;
  font-weight: 700;
  color: #00477d;
  margin-bottom: 1rem;
}

.s4-title h3 {
  font-family: Poppins-Regular;
  font-size: 1.8rem;
  color: #777777;
  font-weight: 500;
}

.s4-cont {
  width: 100%;
  height: 65%;
}

.form-container {
  display: flex;
  width: 85%;
  height: 70%;
  border-radius: 1rem;
  background-color: white;
  box-shadow: 0px 0px 60px 30px rgba(0, 0, 0, 0.03);
  padding: 1rem;
  margin: 0 auto;
}


.bottom {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}


.bottom h3 {
    font-family: Poppins-Regular;
    font-size: 1.6rem;
    color: black;
    font-weight: 600;
  }

.demo {
  width: 25.2rem;
  height: 6rem;
  background-color: #f7ae40;
  font-size: 2rem;
  font-weight: 600rem;
  color: black;
  text-align: center;
  padding-top: 1.5rem;
  border-radius: 0.5rem;
}

.demo-left {
  width: 40%;
  background-color: #00477d;
  height: 100%;
  padding-left: 5rem;
  position: relative;
  border-radius: 1rem;
}

.demo-left-title {
  width: 100%;
  height: 20%;
  padding-top: 4rem;
  margin-bottom: 8.4rem;
}

.demo-left-title h2 {
  font-family: Poppins-Regular;
  font-size: 2.8rem;
  font-weight: 600;
  color: white;
}

.demo-left-title h3 {
  font-family: Poppins-Regular;
  font-size: 1.8rem;
  font-weight: 400;
  color: #c9c9c9;
}

.demo-left-details {
  height: 80%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.demo-left-details-info {
  display: flex;
  margin-bottom: 4rem;
}

.demo-left-details-info img {
  margin-right: 2.5rem;
  width: 2.4rem;
  height: 2.4rem;
}

.demo-left-details-info h2 {
  font-size: 1.6rem;
  font-weight: 400;
  font-family: Poppins-Regular;
  color: #ffffff;
}

.circle-img {
  position: absolute;
  margin-top: -29.3rem;
  margin-left: 22.43rem;
  border-radius: 1rem;
}

.demo-right {
  width: 60%;
  height: 100%;
  padding-left: 9rem;
  padding-top: 5.9rem;
  padding-right: 7.3rem;
}

.demo-names {
  width: 100%;
  height: 15%;
  display: flex;
  margin-bottom: 2.5rem;
}

.demo-name {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 90%;
  margin-right: 0.5rem;
}

.demo-name h2 {
  font-size: 2rem;
  font-family: Poppins-Regular;
  font-weight: 500;
  margin-bottom: 0.9rem;
}

.demo-name input {
  border: 0.2rem solid #777777;
  border-radius: 0.5rem;
  height: 7rem;
  font-size: 1.8rem;
  padding-left: 1rem;
}

.demo-email {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 15%;
  margin-bottom: 2.5rem;
}

.demo-email h2 {
  font-size: 2rem;
  font-family: Poppins-Regular;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.demo-email input {
  border: 0.2rem solid #777777;
  border-radius: 0.5rem;
  height: 7rem;
  font-size: 1.8rem;
  padding-left: 1rem;
}

.demo-phone {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 15%;
  margin-bottom: 2.5rem;
}

.demo-phone h2 {
  font-size: 2rem;
  font-family: Poppins-Regular;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.demo-phone input {
  border: 0.2rem solid #777777;
  border-radius: 0.5rem;
  height: 7rem;
  font-size: 1.8rem;
  padding-left: 1rem;
}

.demo-message {
  height: 55%;
  width: 100%;
  margin-bottom: 2.5rem;
}

.demo-message h2 {
  font-size: 2rem;
  font-family: Poppins-Regular;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.demo-message input {
  border: 0.2rem solid #777777;
  border-radius: 0.5rem;
  width: 100%;
  height: 19.2rem;
}

.demo-names button {
  width: 18.1rem;
  background-color: #00477d;
  text-align: center;
  color: white;
  border-radius: 0.5rem;
  font-family: Poppins-Regular;
  font-size: 2rem;
  font-weight: 600;
  border: none;
}

#demo-button {
  justify-content: right;
}

@media screen and (min-width: 320px) and (max-width: 992px) {
  .s4-demo {
    height: 95rem;
    margin-top: 15rem;
  }
  .form-container {
    flex-direction: column;
    width: 95%;
  }
  .demo-left {
    padding-left: 2rem;
    width: 100%;
    height: 20%;
  }

  .demo-left-title {
    height: 40%;
    margin-bottom: 0;
  }

  .demo-left-details {
    height: 55%;
    padding-bottom: 7rem;
  }
  .demo-left-title h2 {
    font-size: 1.8rem;
  }

  .demo-left-title h3 {
    font-size: 1.6rem;
  }

  .demo-left-details h2 {
    font-size: 1.3rem;
  }

  .demo-left-details-info {
    align-items: center;
  }

  .demo-left-details-info img {
    margin-right: 0.5rem;
    width: 2rem;
    height: 2rem;
  }
  .circle-img {
    display: none;
  }

  .demo-right {
    padding-left: 0;
    padding-right: 0;
    width: 100%;
  }

  .demo-name h2 {
    font-size: 1.6rem;
  }

  .demo-name input {
    height: 4rem;
  }

  .demo-email h2 {
    font-size: 1.6rem;
  }

  .demo-email input {
    height: 4rem;
  }

  .demo-phone h2 {
    font-size: 1.6rem;
  }

  .demo-phone input {
    height: 4rem;
  }

  .demo-message h2 {
    font-size: 1.6rem;
  }

  .demo-message input {
    height: 10rem;
  }

  .demo-names button {
    width: 12.1rem;
    height: 4.2rem;
    font-size: 1.6rem;
  }
  .s4-title {
    height: 15% !important; 
  }
}
